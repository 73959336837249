<template>
  <div>
    <b-card class="hold-form">
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form
          class="position-relative form-top"
          @submit.prevent="handleSubmit(editUser)"
        >
          <b-row>
            <b-col cols="12" lg="3">
              <label class="text-muted mb-3 d-none d-lg-block">
                {{ $t("auth.userImage") }}
              </label>
              <div
                class="userProfile-img-wrapper d-flex justify-content-center align-items-center mx-auto d-lg-block m-lg-0 mb-5 imgDiv position-relative"
              >
                <input
                  type="file"
                  class="d-none"
                  :id="`profile-uploader-user-profile`"
                  @change="addFile"
                />
                <label
                  class="userProfile-img-label m-0 w-100 h-100 position-relative"
                  :for="`profile-uploader-user-profile`"
                >
                  <div
                    class="userProfile-img"
                    :style="{
                      'background-image': 'url(' + currentDoc + ')',
                      'background-size': 'cover',
                      'background-repeat': 'no-repeat',
                    }"
                  ></div>
                  <div
                    class="position-absolute align-items-center justify-content-center editUserImg"
                  >
                    <span class="text-white font-size-16">{{
                      $t("auth.change")
                    }}</span>
                  </div>
                </label>
              </div>
            </b-col>

            <b-col cols="12" lg="9">
              <input-form
                class="mb-3"
                v-model="userInfo.name"
                :validate="'required'"
                :placeholder="$t('auth.userName')"
                :name="$t('auth.userName')"
                :label="$t('auth.userName')"
              />
              <input-form
                class="mb-3"
                v-model="userInfo.email"
                :placeholder="$t('auth.email')"
                :name="$t('auth.email')"
                :label="$t('auth.email')"
                type="email"
                disabled
              />

              <input-form
                :placeholder="$t('auth.phoneNumber')"
                :name="$t('auth.phoneNumber')"
                :label="$t('auth.phoneNumber')"
                v-model="userInfo.phone"
                id="phone-code-primary_phone_number"
                disabled
              />

              <app-button :loading="submitLoading">
                {{ $t("auth.save") }}
              </app-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-card>
    <!-- <b-card class="hold-form">
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form
          class="position-relative form-top"
          @submit.prevent="handleSubmit(changePassword)"
        >
          <b-row>
            <b-col lg="12">
              <input-form
                class="mb-3"
                v-model="userPassword.oldPassword"
                :validate="'required'"
                :placeholder="$t('auth.currentPassword')"
                :name="$t('auth.currentPassword')"
                :label="$t('auth.currentPassword')"
                type="password"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12">
              <input-form
                class="mb-3"
                v-model="userPassword.newPassword"
                :validate="'required|min:8'"
                :placeholder="$t('auth.newPassword')"
                name="newPassword"
                :label="$t('auth.newPassword')"
                type="password"
              />
            </b-col>
            <b-col lg="12">
              <input-form
                class="mb-3"
                v-model="userPassword.confirmNewPassword"
                :validate="'required|confirmed:newPassword'"
                :placeholder="$t('auth.passwordChecked')"
                :name="$t('auth.passwordChecked')"
                :label="$t('auth.passwordChecked')"
                type="password"
              />
            </b-col>

            <b-col lg="12">
              <app-button :loading="passwordLoading">
                {{ $t("auth.save") }}
              </app-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-card> -->
  </div>
</template>

<script>
// import authService from '@/modules/auth/services/auth'
import { core } from "@/config/pluginInit";
import api from "../services/userProfile";
import InputForm from "../../../components/formElements/InputForm.vue";
import AppButton from "../../../components/buttons/appButton.vue";
import userDefaultImage from "@/assets/images/user/default-user-image.png";
export default {
  components: { InputForm, AppButton },
  name: "userSettings",
  data() {
    return {
      currentDoc: JSON.parse(localStorage.getItem("userInfo")).user.avatar
        ? JSON.parse(localStorage.getItem("userInfo")).user.avatar
        : userDefaultImage,
      passwordLoading: false,
      submitLoading: false,

      userInfo: JSON.parse(localStorage.getItem("userInfo")).user,
      avatar: null,
      userPassword: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
    };
  },

  methods: {
    addFile(e) {
      const targetFile = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(targetFile);
      reader.onload = (e) => {
        this.currentDoc = e.target.result;
        this.avatar = targetFile;
        console.log(typeof this.avatar);
      };
    },
    async editUser() {
      this.submitLoading = true;
      const formData = new FormData();
      formData.append("name", this.userInfo.name);
      if (typeof this.avatar == "object" && this.avatar != null)
        formData.append("avatar", this.avatar);
      try {
        const { data } = await api.editUserProfile(formData);
        core.showSnackbar("success", data.message);
        localStorage.setItem("userInfo", JSON.stringify({ user: data.data }));
        this.avatar = null;
      } catch (err) {
        console.log(err);
      } finally {
        this.submitLoading = false;
      }
    },
    async changePassword() {
      this.passwordLoading = true;
      try {
        const { data } = await api.changePassword(this.userPassword);
        core.showSnackbar("success", data.message);
      } catch (err) {
        console.log(err);
      } finally {
        this.passwordLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hold-form {
  padding: 2.5rem;
  &:last-of-type {
    margin: 1.5rem 0;
  }
}
.userProfile-img-wrapper {
  cursor: pointer;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}
.userProfile-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-color: #d2cece;
  background-repeat: no-repeat;
}
.userProfile-img-wrapper:hover .editUserImg {
  display: flex;
}
.editUserImg {
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgba(115, 115, 115, 0.8);
  display: none;
}
.save-btn {
  border-radius: 4px !important;
  padding: 1rem 0 !important;
  margin-top: 1.5rem !important;
}
</style>
